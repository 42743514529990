<template>
  <mds-layout-grid>
    <filter-section />
    <table-section />
  </mds-layout-grid>
</template>

<script>
import FilterSection from '@/components/collection-exchange-monitor/filters/CrawledDocumentsFilterSection';
import TableSection from '@/components/collection-exchange-monitor/crawled-documents/CrawledDocuments';

export default {
  name: 'ProvidersPageEM',
  components: {
    FilterSection,
    TableSection,
  },
};
</script>
